<template>
  <section class="index-network">
    <div class="index-network__container container">
      <h5 class="index-network__pretitle" v-t="'index.network.pretitle'" />
      <h2 class="index-network__title" v-t="'index.network.title'" />
      <p class="index-network__text" v-t="'index.network.text'" />
    </div>
    <div class="index-network__net container desktop">
      <div class="index-network__net-item rocket">
        <svg width="78" height="78" viewBox="0 0 78 78" fill="none" xmlns="http://www.w3.org/2000/svg" v-bind:svg-inline="''" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><circle cx="39" cy="39" r="36" fill="#FCFCFC" stroke="url(#paint0_linear)" stroke-width="5"/><path d="M33.479 49.937a5.002 5.002 0 01-4.906 4.03h-4a1 1 0 01-1-1v-4a5.002 5.002 0 014.03-4.906l.024-.686c.04-1.159.16-2.297.353-3.408h-7.407a1 1 0 01-.929-1.371l4-10a1 1 0 01.929-.629h9c.05 0 .1.004.149.011 4.709-5.368 11.7-8.657 19.393-8.392 1.221.043 2.438.175 3.64.398a1 1 0 01.8.801c1.642 8.862-1.678 17.494-7.993 23.036.007.048.01.097.01.146v9a1 1 0 01-.628.929l-10 4a1 1 0 01-1.372-.929v-7.41a24.65 24.65 0 01-3.408.356l-.685.024zm-1.931-.58l-3.365-3.365a3 3 0 00-2.61 2.975v3h3a3 3 0 002.974-2.61zm8.024-.236v6.37l8-3.2v-6.897a24.509 24.509 0 01-8 3.727zM28.414 37.967a24.52 24.52 0 013.736-8h-6.9l-3.2 8h6.364zm4.558 9.986l1.123-.039c1.123-.039 2.24-.16 3.345-.365 12.073-2.236 20.124-13.662 18.266-25.715a22.647 22.647 0 00-2.66-.249c-12.504-.431-22.99 9.356-23.42 21.86l-.04 1.122 3.386 3.386zm10.6-7.986a6 6 0 110-12 6 6 0 010 12zm0-2a4 4 0 100-8 4 4 0 000 8z" fill="#181818" stroke="#181818" stroke-width=".5"/><defs><linearGradient id="paint0_linear" x1="24.5" y1="82.5" x2="75" y2="-32.5" gradientUnits="userSpaceOnUse"><stop stop-color="#87F99D"/><stop offset="1" stop-color="#FBE56B"/></linearGradient></defs></svg>
        <span v-t="'index.network.net.lap'" />
      </div>
      <div class="index-network__net-item earth">
        <svg width="78" height="78" viewBox="0 0 78 78" fill="none" xmlns="http://www.w3.org/2000/svg" v-bind:svg-inline="''" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><circle cx="39" cy="38.658" r="36" fill="#FCFCFC" stroke="url(#paint0_linear)" stroke-width="5"/><path d="M36.6 56.149v-2.476l-3.707-3.707a1 1 0 01-.293-.708v-5.585l-1.707-1.707a1 1 0 01-.293-.708v-2a1 1 0 011-1h10a3 3 0 013 3v2a1 1 0 001 1 3 3 0 013 3 1 1 0 001 1h3.97a17.916 17.916 0 003.03-10c0-7.837-5.008-14.504-12-16.975v1.976a5 5 0 01-5 5h-2a1 1 0 00-1 1v2a3 3 0 01-3 3h-3v1a1 1 0 01-.293.707l-2 2a1 1 0 01-1.414 0l-5.38-5.381a17.983 17.983 0 00-.913 5.673c0 9.266 7 16.896 16 17.89zm2 .11c5.331 0 10.121-2.318 13.417-6H49.6a3 3 0 01-3-3 1 1 0 00-1-1 3 3 0 01-3-3v-2a1 1 0 00-1-1h-9v.585l1.707 1.707a1 1 0 01.293.707v5.586l3.707 3.707a1 1 0 01.293.707v3zm-.818 1.983a1.006 1.006 0 01-.464-.024C26.87 57.557 18.6 48.873 18.6 38.258c0-11.045 8.955-20 20-20 11.046 0 20 8.955 20 20 0 11.046-8.954 20-20 20-.274 0-.547-.005-.818-.016zm-15.46-27.676l5.278 5.278 1-1v-1.586a1 1 0 011-1h4a1 1 0 001-1v-2a3 3 0 013-3h2a3 3 0 003-3v-2.553a18.06 18.06 0 00-4-.446c-7.189 0-13.394 4.214-16.278 10.307z" fill="#181818" stroke="#181818" stroke-width=".5"/><defs><linearGradient id="paint0_linear" x1="24.5" y1="82.158" x2="75" y2="-32.842" gradientUnits="userSpaceOnUse"><stop stop-color="#87F99D"/><stop offset="1" stop-color="#FBE56B"/></linearGradient></defs></svg>
        <span v-t="'index.network.net.earth'" />
      </div>
      <div class="index-network__net-item star">
        <svg width="78" height="78" viewBox="0 0 78 78" fill="none" xmlns="http://www.w3.org/2000/svg" v-bind:svg-inline="''" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><circle cx="39" cy="38.658" r="36" fill="#FCFCFC" stroke="url(#paint0_linear)" stroke-width="5"/><path d="M39 14.658l5.388 16.584h17.437l-14.107 10.25 5.389 16.583L39 47.825l-14.107 10.25 5.389-16.584-14.107-10.249h17.437L39 14.658z" stroke="#181818" stroke-width="2.5" stroke-linecap="round" stroke-linejoin="round"/><defs><linearGradient id="paint0_linear" x1="24.5" y1="82.158" x2="75" y2="-32.842" gradientUnits="userSpaceOnUse"><stop stop-color="#87F99D"/><stop offset="1" stop-color="#FBE56B"/></linearGradient></defs></svg>
        <span v-t="'index.network.net.star'" />
      </div>
      <div class="index-network__net-item wallet">
        <svg width="78" height="78" viewBox="0 0 78 78" fill="none" xmlns="http://www.w3.org/2000/svg" v-bind:svg-inline="''" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><circle cx="39" cy="38.658" r="36" fill="#FCFCFC" stroke="url(#paint0_linear)" stroke-width="5"/><path d="M56.6 31.658a4.976 4.976 0 00-3-1h-1.1a5.003 5.003 0 00-4.9-4h-18a5.003 5.003 0 00-4.9 4h-1.1c-1.658 0-3-1.342-3-3v-2c0-1.658 1.342-3 3-3h30c1.658 0 3 1.342 3 3v6zm-29.83-1a3.001 3.001 0 012.83-2h18c1.306 0 2.418.834 2.83 2H26.77zm29.83 8h-7a5 5 0 00-5 5 5 5 0 005 5h7v3a3 3 0 01-3 3h-30a3 3 0 01-3-3v-20c.836.628 1.874 1 3 1h30a3 3 0 013 3v3zm0 8h-7a3 3 0 110-6h7v6zm-3-26h-30a5 5 0 00-5 5v26a5 5 0 005 5h30a5 5 0 005-5v-26a5 5 0 00-5-5z" fill="#181818" stroke="#181818" stroke-width=".5"/><defs><linearGradient id="paint0_linear" x1="24.5" y1="82.158" x2="75" y2="-32.842" gradientUnits="userSpaceOnUse"><stop stop-color="#87F99D"/><stop offset="1" stop-color="#FBE56B"/></linearGradient></defs></svg>
        <span v-t="'index.network.net.wallet'" />
      </div>
      <div class="index-network__net-item nft">
        <svg width="78" height="78" viewBox="0 0 78 78" fill="none" xmlns="http://www.w3.org/2000/svg" v-bind:svg-inline="''" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><circle cx="39" cy="38.658" r="36" fill="#FCFCFC" stroke="url(#paint0_linear)" stroke-width="5"/><path d="M28.476 39.476h12.5a5.835 5.835 0 005.784-5.066 5.835 5.835 0 01.883-11.6 5.833 5.833 0 01.793 11.613 7.5 7.5 0 01-7.46 6.72h-12.5v3.392a5.835 5.835 0 11-1.666 0V34.417a5.835 5.835 0 111.666 0v5.06zm-5-10.833a4.167 4.167 0 108.334 0 4.167 4.167 0 00-8.334 0zm24.167-4.167a4.167 4.167 0 100 8.334 4.167 4.167 0 000-8.334z" fill="#181818"/><path d="M23.476 50.31a4.167 4.167 0 008.334 0m-3.334-10.834h12.5a5.835 5.835 0 005.784-5.066 5.835 5.835 0 01.883-11.6 5.833 5.833 0 01.793 11.613 7.5 7.5 0 01-7.46 6.72h-12.5v3.392a5.835 5.835 0 11-1.666 0V34.417a5.835 5.835 0 111.666 0v5.06zm-5-10.833a4.167 4.167 0 108.334 0 4.167 4.167 0 00-8.334 0zm24.167-4.167a4.167 4.167 0 100 8.334 4.167 4.167 0 000-8.334z" stroke="#181818" stroke-width=".5"/><defs><linearGradient id="paint0_linear" x1="24.5" y1="82.158" x2="75" y2="-32.842" gradientUnits="userSpaceOnUse"><stop stop-color="#87F99D"/><stop offset="1" stop-color="#FBE56B"/></linearGradient></defs></svg>
        <span v-t="'index.network.net.nft'" />
      </div>
      <div class="index-network__net-item profile">
        <svg width="78" height="78" viewBox="0 0 78 78" fill="none" xmlns="http://www.w3.org/2000/svg" v-bind:svg-inline="''" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><circle cx="39" cy="38.658" r="36" fill="#FCFCFC" stroke="url(#paint0_linear)" stroke-width="5"/><path d="M45.156 40.881l6.913 3.143A5 5 0 0155 48.576V50a5 5 0 01-5 5H28a5 5 0 01-5-5v-1.424a5 5 0 012.931-4.552l6.913-3.143A9.983 9.983 0 0129 33c0-5.523 4.477-10 10-10s10 4.477 10 10a9.983 9.983 0 01-3.844 7.881zm-10.233 1.253l-8.164 3.71A3 3 0 0025 48.577V50a3 3 0 003 3h22a3 3 0 003-3v-1.424a3 3 0 00-1.759-2.731l-8.163-3.711A9.966 9.966 0 0139 43a9.966 9.966 0 01-4.078-.866zM39 41a8 8 0 100-16 8 8 0 000 16z" fill="#181818" stroke="#181818" stroke-width=".5"/><defs><linearGradient id="paint0_linear" x1="24.5" y1="82.158" x2="75" y2="-32.842" gradientUnits="userSpaceOnUse"><stop stop-color="#87F99D"/><stop offset="1" stop-color="#FBE56B"/></linearGradient></defs></svg>
        <span v-t="'index.network.net.profile'" />
      </div>
    </div>
    <div class="index-network__net container mobile">
      <div class="index-network__net-block">
        <ul>
          <li>
            <span v-t="'index.network.net.lap'" />
          </li>
          <li>
            <span v-t="'index.network.net.star'" />
          </li>
          <li>
            <span v-t="'index.network.net.earth'" />
          </li>
        </ul>
      </div>
      <div class="index-network__net-block">
        <ul>
          <li>
            <span v-t="'index.network.net.wallet'" />
          </li>
          <li>
            <span v-t="'index.network.net.nft'" />
          </li>
          <li>
            <span v-t="'index.network.net.profile'" />
          </li>
        </ul>
      </div>
    </div>
  </section>
</template>

<script>
export default {
name: "Network"
}
</script>

<style lang="scss">
@import '@/assets/scss/elements/index/network';
</style>